
import {Component, Vue} from 'vue-property-decorator';
import Carousel from '@/components/Carousel.vue';
import ICarouselData from '@/dataDescriptor/ICarouselData';

@Component<Home>({
    components: {Carousel},
    metaInfo()
    {
        return {
            title: 'Hlavní stránka',
            titleTemplate: '%s | Půjčovna Haryků',
            meta: [
                {property: 'og:title', content: 'Sportster Iron 883 | Půjčovna Haryků'},
                {property: 'og:type', content: 'article'},
                {property: 'og:url', content: 'https://www.pujcovna-haryku.cz'},
                {property: 'og:description', content: 'Jsme nadšenci do motocyklů a speciálně do značky Harley Davidson, chceme svoje stroje a radost z jízdy sdílet s ostatními. Proto jsme na jaře 2019 založili malou půjčovnu motocyklů v Pardubicích.'},
                ...(this.carouselImages.length !== 0 ? [{property: 'og:image', content: 'https://www.pujcovna-haryku.cz' + this.carouselImages[0].src}] : []),
                ...(this.carouselImages.length !== 0 ? [{property: 'twitter:image:src', content: 'https://www.pujcovna-haryku.cz' + this.carouselImages[0].src}] : []),
                {property: 'og:image:width', content: '1000'},
                {property: 'og:site_name', content: 'Půjčovna Haryků'},
            ],
        };
    },
})
export default class Home extends Vue
{
    get carouselImages(): ICarouselData[]
    {
        return [
            {
                src: require('@/assets/images/reference/reference_04.jpg'),
                alt: 'Sport Glide',
                title: 'Štěpán',
                content: 'Krásný svezení. 😍 To je dost, že mě taky vyvezl!',
            },
            {
                src: require('@/assets/images/reference/reference_01.jpg'),
                alt: 'Iron 883',
                title: 'Ivo',
                content: 'Děkuji Jan Kopp za zapůjčení, perfektní domluva, motorka se dá půjčit i na jeden den o víkendu a vrátit třeba i pozdě večer. 👍\nStroj jsem měl přistavený přímo u nádraží v Pardubkách. 😎',
            },
            {
                src: require('@/assets/images/reference/reference_02.jpg'),
                alt: 'Iron 883',
                title: 'Špíca',
                content: 'Děkuji, že jste mi tento zážitek umožnili. Jedinej problém - malá nádrž 😀 jinak luxusní poježdění, všechno mě bolí, ale bylo to super.',
            },
            {
                src: require('@/assets/images/reference/reference_03.jpg'),
                alt: 'Sport Glide',
                title: 'Petr',
                content: 'Stroj je opravdu úžasnej, měl jsi pravdu, krásně se ovládá a jezdí nádhěrně. 😃',
            },
        ];
    }
}
