
import {Component, Prop, Vue} from 'vue-property-decorator';
import ICarouselData from '@/dataDescriptor/ICarouselData';

@Component<Carousel>({})
export default class Carousel extends Vue
{
    @Prop({required: true})
    private images!: ICarouselData[];

    private pointer: number = 0;

    private length: number = 0;

    public mounted()
    {
        if (this.images && this.images.length > 0) {
            this.length = this.images.length;
        }

        this.pointer = Math.floor(Math.random() * (this.images.length - 1));
    }

    public leftClick(event: MouseEvent)
    {
        this.movePointer(-1);
    }

    public rightClick(event: MouseEvent)
    {
        this.movePointer(1);
    }

    private movePointer(index: number)
    {
        if (index > 0) {
            if ((this.pointer + index) !== this.length) {
                this.pointer = this.pointer + index;
            }
        } else {
            if ((this.pointer + (index)) >= 0) {
                this.pointer = this.pointer + (index);
            }
        }

    }

    get isFirst(): boolean
    {
        return this.pointer === 0;
    }

    get isLast(): boolean
    {
        return this.pointer === (this.length - 1);
    }

    get actualImage(): ICarouselData | null
    {
        return this.images[this.pointer];
    }
}
